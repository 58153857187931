/* eslint-disable @next/next/no-img-element */
import Loading from "../Loading";

interface IButtonProps {
  type: string;
  label?: string;
  labelColor?: string;
  onClick: any;
  className?: string;
  icon?: any;
  size?: string;
  disabled?: boolean;
  isLoading?: boolean;
  imageIcon?: {
    src?: string;
    alt?: string;
    className?: string;
  };
}

const Button = ({
  type,
  label,
  labelColor,
  onClick,
  className,
  icon,
  size,
  disabled,
  isLoading,
  imageIcon,
}: IButtonProps) => {
  let buttonClass: string;

  switch (type) {
    case "secondary":
      buttonClass = "bg-black text-white";
      break;
    case "tertiary":
      buttonClass = "bg-gray-100 text-black";
      break;
    case "danger":
      buttonClass = "bg-red text-white";
      break;
    default:
      buttonClass = "bg-red text-white";
  }

  if (disabled) buttonClass = "bg-gray-100 hover:shadow-none cursor-not-allowed";
  else buttonClass += " hover:shadow-lg";

  switch (size) {
    case "sm":
      buttonClass += " h-8";
      break;
    case "md":
      buttonClass += "xs:h-14 sm:h-10 md:h-10 lg:h-10";
      break;
    default:
      buttonClass += " h-10";
  }

  return (
    <div
      className={`transition-all p-3 text-base rounded-md flex justify-center items-center cursor-pointer ${className} ${buttonClass}`}
      style={{ transition: "all .15s ease" }}
      onClick={!disabled ? onClick : () => {}}
    >
      <div className="flex justify-center items-center">
        {!icon || <div className="-mb-1 -mr-1">{icon}</div>}
        {imageIcon?.src && <img src={imageIcon.src} className={`h-6 w-6 mr-4 object-contain`} alt={`${imageIcon.alt || ""}`}/>}
        {isLoading && <div className="-mr-1"><Loading isLoading={isLoading} /></div>}
		<div className={labelColor}>{label}</div>
      </div>
    </div>
  );
};

export default Button;
